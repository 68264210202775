import type { InputTextProps } from './types'

import { Input } from 'components/global/InputFields'

import * as S from './styles'

const InputText: React.FC<InputTextProps> = ({
  label = '',
  helperText,
  listProp = { id: '', data: [] },
  autoComplete = 'true',
  inputProps = {},
  ...props
}) => {
  return (
    <S.Wrapper>
      <Input
        sx={{ width: '100%' }}
        id={`${label}_input`}
        autoComplete={autoComplete}
        variant="outlined"
        label={label}
        helperText={helperText}
        inputProps={
          listProp.id
            ? {
                list: listProp.id,
                ...inputProps,
              }
            : { ...inputProps }
        }
        {...props}
      />

      {listProp.data.length > 0 && (
        <datalist id={listProp.id}>
          {listProp.data.map((value) => (
            <option key={value} value={value} />
          ))}
        </datalist>
      )}
    </S.Wrapper>
  )
}

export default InputText
