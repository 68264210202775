export const pages = [
  [
    {
      module: 'panel',
      link: '',
    },
    {
      module: 'realties',
      link: 'realties',
    },
  ],
  [
    {
      module: 'messages',
      link: 'leads',
    },
    {
      module: 'clients',
      link: 'clients',
    },
  ],
  [
    {
      module: 'companies',
      link: 'companies',
    },
    {
      module: 'brokers',
      link: 'brokers',
    },
    {
      module: 'agencies',
      link: 'agencies',
    },
    {
      module: 'crms',
      link: 'crms',
    },
    {
      module: 'persons',
      link: 'persons',
    },
  ],
  [
    {
      module: 'campaigns',
      link: 'campaigns',
    },
    {
      module: 'leadAdsForms',
      link: 'lead-ads-forms',
    },
    {
      module: 'leadsSubmission',
      link: 'leads-submission',
    },
    {
      module: 'services',
      link: 'services',
    },
  ],
  [
    {
      module: 'reports',
      link: 'reports',
    },
  ],
  [
    {
      module: 'cms',
      internals: [
        {
          module: 'highlights',
          link: 'highlights',
        },
        {
          module: 'homeApto',
          link: 'home-apto',
        },
        {
          module: 'attributes',
          link: 'attributes',
        },
        {
          module: 'locations',
          link: 'locations',
        },
        {
          module: 'partners',
          link: 'partners',
        },
        {
          module: 'jobPositions',
          link: 'jobs',
        },
        {
          module: 'railStation',
          link: 'rail-station',
        },
        {
          module: 'frequentlyAskedQuestions',
          link: 'customer-faq',
        },
        {
          module: 'propertyIndexes',
          link: 'property-indexes',
        },
        {
          module: 'institutionals',
          link: 'institutionals',
        },
        {
          module: 'financialInstitutions',
          link: 'financing-fees',
        },
        {
          module: 'savedSearches',
          link: 'saved-searches',
        },
        {
          module: 'feeds',
          link: 'feeds',
        },
      ],
    },
  ],
  [
    {
      module: 'configs',
      internals: [
        {
          module: 'customization',
          link: 'customization',
        },
        {
          module: 'vince',
          link: 'vince',
        },
        {
          module: 'users',
          link: 'users',
        },
        {
          module: 'debug',
          link: 'debug',
        },
        {
          module: 'logs',
          link: 'logs',
        },
      ],
    },
  ],
]
