import styled, { css } from 'styled-components'

export const Brain = styled.div`
  ${({ theme, $sidebarWidth, $sidebarOpen, $subSidebar }) => css`
    display: grid;
    height: 100vh;

    .sidebar {
      grid-area: sidebar;
    }

    .subSidebar {
      grid-area: subSidebar;
    }

    .main {
      grid-area: main;
    }

    grid-template-rows: 66px calc(100% - 66px);
    grid-template-areas:
      'sidebar'
      'main';

    ${$subSidebar &&
    css`
      grid-template-rows: 66px 66px calc(100% - 66px - 66px);
      grid-template-areas:
        'sidebar'
        'subSidebar'
        'main';
    `}

    @media ${theme.device.laptopS} {
      grid-template-rows: 100%;
      grid-template-columns: ${$sidebarOpen
        ? `${$sidebarWidth}px calc(100% - ${$sidebarWidth}px)`
        : '82px calc(100% - 82px)'};
      grid-template-areas: 'sidebar main';

      ${$subSidebar &&
      css`
        grid-template-rows: 66px calc(100% - 66px);
        grid-template-areas:
          'sidebar subSidebar'
          'sidebar main';
      `}
    }

    @media ${theme.device.desktopS} {
      grid-template-rows: 100%;
      grid-template-columns: ${$sidebarOpen
        ? `${$sidebarWidth}px calc(100% - ${$sidebarWidth}px)`
        : ' 82px calc(100% - 82px)'};
      grid-template-areas: 'sidebar main';

      ${$subSidebar &&
      css`
        grid-template-columns: ${$sidebarOpen
          ? `${$sidebarWidth}px 225px calc(100% - ${$sidebarWidth}px - 225px)`
          : '82px 225px calc(100% - 82px - 225px)'};
        grid-template-areas: 'sidebar subSidebar main';
      `}
    }
  `}
`
