import { captureException } from '@sentry/react'
import { clearToken } from './functions/storage'
import notify from './Toast'

/**
 * @param {string | object} err
 * Mensagem para ser traduzida para o usuário.
 *
 * @returns {string | string[]}
 */
export function getGraphqlErrorMessages(err) {
  if (typeof err === 'object') {
    if (err.graphQLErrors?.length > 0) {
      const messages = []

      err.graphQLErrors.forEach((error) => {
        if (error.message === 'validation') {
          for (const item in err.graphQLErrors[0].validation) {
            messages.push(...err.graphQLErrors[0].validation[item])
          }
        } else {
          messages.push(error.message)
        }
      })

      return messages
    } else if (typeof err?.message === 'string') return err.message
  } else return typeof err === 'string' ? err : `${err}`
}

export const notifyGraphqlError = (err) => {
  if (
    err?.graphQLErrors &&
    err.graphQLErrors?.[0]?.status?.message === 'Unauthorized'
  ) {
    clearToken()
    location.reload(true)
  }

  const parsedMessage = getGraphqlErrorMessages(err)

  if (typeof parsedMessage === 'object') {
    notify(
      'error',
      parsedMessage.map((item, i) => <p key={i}>{item}</p>),
    )
  } else {
    notify(
      'error',
      <>
        Algo deu errado, tente novamente em alguns instantes.
        <br /> <p className="apto_text--small">Erro: {parsedMessage}</p>
      </>,
    )
  }

  captureException(parsedMessage)
}

export function notifyYupError(err) {
  const messageErr = err?.errors ?? err?.message ?? err
  if (Array.isArray(messageErr)) {
    notify(
      'error',
      <div>
        {messageErr.map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </div>,
    )
  } else if (typeof messageErr === 'string' && messageErr.includes(',')) {
    notify(
      'error',
      <div>
        {messageErr.split(',').map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </div>,
    )
  } else {
    notify('error', messageErr)
  }
}
