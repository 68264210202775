import styled from 'styled-components'

export const Wrapper = styled.div`
  .Toastify__toast-theme--dark {
    background: ${({ theme }) => theme.colors.background};
  }

  .MuiCircularProgress-root {
    width: 24px !important;
    height: 24px !important;
  }

  .Toastify__progress-bar {
    height: 6px;
    background: #fff;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.74) 80%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .Toastify__toast-container {
    width: auto;
    max-width: 80%;
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .Toastify__toast {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.border.radius};
    min-height: 55px;
    &--success,
    &--info,
    &--error {
      color: #fff;
      p {
        color: #fff;
      }
    }
    &-icon {
      display: none;
    }
    &--success {
      background: ${({ theme }) => theme.colors.primary};
    }
    &--info {
      background: ${({ theme }) => theme.colors.primary};
    }
    &--error {
      background: ${({ theme }) => theme.colors.error};
    }
    &--warning {
      background: ${({ theme }) => theme.colors.warning};
      color: ${({ theme }) => theme.colors.text};
      .Toastify__close-button {
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`
