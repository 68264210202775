import styled, { css } from 'styled-components'
import { LogoBraiReduced } from './svgs'

export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    > svg {
      width: 40px;
      height: 40px;

      path {
        fill: ${({ theme }) => theme.sidebar.icon};
      }
    }

    @media ${theme.device.laptopS} {
      > svg {
        width: 50px;
        height: 50px;
      }
    }
  `}
`

const Logo = () => {
  return (
    <LogoWrapper>
      <LogoBraiReduced />
    </LogoWrapper>
  )
}

export default Logo
