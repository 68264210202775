import { ptBR } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'

export const theme = {
  border: {
    radius: '5px',
    xradius: '6px',
    xxradius: '8px',
  },
  device: {
    mobile: '(max-width: 480px)',
    tabletS: '(min-width: 481px)',
    tablet: '(max-width: 768px)',
    laptopS: '(min-width: 769px)',
    laptop: '(max-width: 1024px)',
    desktopS: '(min-width: 1025px)',
  },
  font: {
    family: "'TTNorms', sans-serif",
    weigth: {
      normal: 400,
      regular: 500,
      bold: 700,
    },
    sizes: {
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xlarge: '20px',
      xxlarge: '24px',
      title: '32px',
      subtitle: '28px',
    },
  },
  colors: {
    primary: '#0066ff',
    primaryLight: '#0066ff88',
    primaryLighter: '#0066ff14',
    primaryDark: '#001290',
    secondary: '#FF6666',
    secondaryDark: '#BE0000',
    text: '#414141',
    textLight: '#A8AAAC',
    standard: '#A8AAAC',
    standardDark: '#8d8d8d',
    //settings
    warning: '#FFD400',
    border: '#A8AAAC',
    disabled: '#F3F3F3',
    menu: '#FFFFFF',
    error: '#FF6666',
    gray: '#f5f5f5',
    //background
    background: '#414141',
    bgLight: '#A8AAAC',
    'bgLight-02': '#f3f3f3',
    BGLight: '#FFFFFF',
  },
  spacings: {
    xxsmall: '4px',
    xsmall: '8px',
    small: '10px',
    medium: '12px',
    large: '14px',
    xlarge: '16px',
    xxlarge: '20px',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
} as const

export const sidebarTheme = {
  dark: {
    sidebar: {
      background: '#414141',
      text: '#ffffff',
      border: '#ffffff',
      icon: '#ffffff',
      scrollbar: '#aaa',
    },
  },
  white: {
    sidebar: {
      text: '#414141',
      background: '#f3f3f3',
      border: '#414141',
      icon: theme.colors.primary,
      scrollbar: '#777',
    },
  },
}

export const MuiCustomTheme = createTheme(
  {
    palette: {
      primary: {
        main: theme.colors.primary,
        light: theme.colors.primaryLight,
        dark: theme.colors.primaryDark,
      },
      secondary: {
        main: theme.colors.secondary,
        light: theme.colors.secondary,
        dark: theme.colors.secondaryDark,
      },
      info: {
        main: theme.colors.primary,
      },
      text: {
        primary: theme.colors.text,
        secondary: theme.colors.textLight,
      },
      standard: {
        main: theme.colors.standard,
      },
      icon: {
        main: theme.colors.standard,
      },
      warning: {
        main: theme.colors.warning,
      },
      error: {
        main: theme.colors.error,
        light: theme.colors.error,
        dark: theme.colors.error,
      },
      white: {
        main: '#fff',
      },
      dark: {
        main: theme.colors.text,
      },
    },
    typography: {
      fontFamily: 'inherit',
    },
    shape: {
      borderRadius: 5,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: '#fff',
          },
          input: {
            background: '#fff',
            borderRadius: '5px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: theme.colors.secondary,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '8px 24px',
          },
          outlined: {
            '&:hover': {
              color: '#fff',
            },
          },
          contained: {
            color: '#fff',
          },
          outlinedSecondary: {
            '&:hover': {
              background: theme.colors.secondary,
            },
          },
          outlinedPrimary: {
            '&:hover': {
              background: theme.colors.primary,
            },
          },
          outlinedStandard: {
            '&:hover': {
              background: theme.colors.standard,
            },
          },
        },
      },
    },
  },
  ptBR,
)
