import styled, { css } from 'styled-components'
import { ModulesSection } from './Modules/styles'

export const SidebarContainer = styled.div`
  position: relative;
  z-index: 1010;
  user-select: none;

  .MuiBadge-badge {
    color: #fff;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const ExitSection = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px 0;
    border-top: 1px solid ${theme.sidebar.border}40;
    border-bottom: 1px solid ${theme.sidebar.border}40;
  `}
`

export const SibebarContent = styled.div`
  ${({ theme, $width, $open }) => css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 16px;
    width: ${$open ? `${$width}px` : '82px'};
    height: 100%;
    padding: 8px;
    white-space: nowrap;
    background-color: ${theme.sidebar.background};
    box-shadow:
      0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%),
      0px 1px 8px 0px rgb(0 0 0 / 12%);
    position: fixed;
    z-index: 800;

    .menuOpen {
      display: ${$open ? 'none' : 'initial'};
    }

    .expand,
    .closeText,
    .logoOpened,
    .menuClose {
      display: ${$open ? 'initial' : 'none'};
    }

    .closeIcon {
      transition: 0.4s ease-in-out;
      transform: ${$open ? 'rotate(180deg)' : 'none'};
    }

    @media ${theme.device.laptopS} {
      padding: 16px 8px;

      .MuiAvatar-root {
        width: 40px;
        height: 40px;
      }

      .textUser {
        display: ${$open ? 'initial' : 'none'};
      }
    }

    @media ${theme.device.tablet} {
      width: 100%;
      height: ${$open ? '100%' : '66px'};

      ${ExitSection} {
        display: none;
      }

      ${ModulesSection} {
        display: ${$open ? 'inherit' : 'none'};
      }
    }

    @media ${theme.device.mobile} {
      ${ExitSection} {
        padding: 6px 4px;
      }
    }
  `}
`

export const ByApto = styled.div`
  ${({ theme, $sidebarOpen }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 58px;
    color: ${theme.sidebar.text};
    font-size: ${theme.font.sizes.small};

    ${!$sidebarOpen &&
    css`
      @media ${theme.device.tablet} {
        display: none;
      }
    `}
  `}
`

export const ResizeBar = styled.div`
  --resize-bar-width: 10px;

  ${({ theme, $open }) => css`
    display: ${$open ? 'flex' : 'none'};
    justify-content: center;
    width: var(--resize-bar-width);
    height: 100%;
    position: absolute;
    right: calc(var(--resize-bar-width) / -2);
    z-index: 1011;

    &:hover {
      cursor: ew-resize;
    }

    @media ${theme.device.tablet} {
      display: none;
    }
  `}
`
