import { useState, Suspense } from 'react'
import * as S from './styles'
import useSidebarStore from 'components/Sidebar/useStore'
import useStore from 'components/SubSideBar/useStore'
import Sidebar from 'components/Sidebar'
import { SubSidebar } from 'components/SubSideBar'
import Loader from 'components/Loader'
import { ApolloProvider } from '@apollo/client'
import { getBrainApolloClient } from 'Apollo/Config'

const client = getBrainApolloClient()

const BrainLayout = ({ children }) => {
  const sidebarWidthStorage = localStorage.getItem('@brain:sidebarWidth') ?? 255

  const sidebarOpen = useSidebarStore((state) => state.open)
  const subSidebar = useStore((state) => state.display)

  const [sidebarWidth, setSidebarWidth] = useState(sidebarWidthStorage)

  function handleResize(newWidth) {
    setSidebarWidth(newWidth)
  }

  return (
    <ApolloProvider client={client}>
      <S.Brain
        $sidebarWidth={sidebarWidth}
        $sidebarOpen={sidebarOpen}
        $subSidebar={subSidebar}
      >
        <Sidebar width={sidebarWidth} onResizing={handleResize} />
        <SubSidebar />
        <Suspense fallback={<Loader suspense />}>{children}</Suspense>
      </S.Brain>
    </ApolloProvider>
  )
}

export default BrainLayout
