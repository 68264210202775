import { CopyAll, Done, Error, Info, Save, Warning } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import { CustomToast } from './styles'
import copy from 'copy-to-clipboard'

const icons = {
  save: <Save />,
  success: <Done />,
  error: <Error />,
  warning: <Warning />,
  info: <Info />,
  pending: <CircularProgress color="inherit" />,
}

export function CustomComponent({ type, label, toastId }) {
  const textToastId = 'text_' + toastId

  function copyToClipboard() {
    const toastText = document.getElementById(textToastId)
    if (toastText) {
      copy(toastText.innerText, {
        format: 'text/plain',
      })
    }
  }

  return (
    <CustomToast>
      <div className="notify_wrapper">
        {icons[type]}
        <div className="notify_text" id={textToastId}>
          {label}
        </div>
      </div>
      <IconButton
        title="Copiar para área de transferência"
        onClick={copyToClipboard}
      >
        <CopyAll color="white" fontSize="small" />
      </IconButton>
    </CustomToast>
  )
}
