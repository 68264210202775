import { getBrainApolloClient, refreshToken } from 'Apollo/Config'
import Loader from 'components/Loader'
import { createContext, useEffect, useMemo, useState } from 'react'
import { clearToken, setToken } from 'utils/functions/storage'
import Cookies from 'universal-cookie'
import { USER_PROFILE } from 'Apollo/Queries/UserProfile'
import { modules } from './modules'
import { setUser as setContextUser } from '@sentry/react'

const cookies = new Cookies()

let interval

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [ready, setReady] = useState(false)

  const login = async (token) => {
    setToken(token)
    await getUser()
  }

  const logout = () => {
    clearToken()
    setUser(null)
  }

  async function getUser() {
    await getBrainApolloClient()
      .query({
        query: USER_PROFILE,
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        if (res.data?.profile) {
          setUser(parseUser(res.data.profile))
          setContextUser({
            id: res.data.profile.id,
            username: res.data.profile.name,
            email: res.data.profile.email,
          })
        }
      })
      .catch(() => logout())
  }

  function parseUser(user) {
    const permissions = {}
    const userPermission = user.permissions.reduce(
      (prev, current) => ({
        ...prev,
        [current.module]: current.actions.length,
      }),
      {},
    )

    modules.forEach((mod) => {
      const modName = mod[0]
      if (mod?.[1]) {
        let perm = 2

        for (const modDep of mod[1]) {
          if (!userPermission[modDep] > 0) {
            perm = 0
            break
          }
        }

        permissions[modName] = perm
      } else {
        permissions[modName] = userPermission[modName] ?? 0
      }
    })

    return { ...user, permissions }
  }

  async function handleRefreshToken() {
    const token = await refreshToken()

    if (token) await login(token)
    else logout()

    setReady(true)
  }

  async function init() {
    const authToken = cookies.get('auth_token')
    const tokenLifetime = cookies.get('token_lifetime') ?? false

    if (!tokenLifetime && authToken) {
      await handleRefreshToken()
    } else if (authToken && tokenLifetime) {
      await getUser()
    }

    setReady(true)
  }

  function tokenInterval() {
    const authToken = cookies.get('auth_token')
    const tokenLifetime = cookies.get('token_lifetime') ?? false

    return setInterval(async () => {
      if (!tokenLifetime && authToken) {
        const token = await refreshToken()

        if (token) {
          setToken(token)
        } else {
          logout()
        }
      }
    }, 60000 * 20)
  }

  useEffect(() => {
    if (!ready) {
      init()
    }

    interval = tokenInterval()

    function updateTokenVisibility() {
      if (document.hidden) {
        clearInterval(interval)
      } else {
        interval = tokenInterval()
      }
    }

    document.addEventListener('visibilitychange', updateTokenVisibility)

    return () => {
      document.removeEventListener('visibilitychange', updateTokenVisibility)
    }
  }, [])

  const value = useMemo(() => ({ user, login, logout }), [user])

  if (!ready) return <Loader />
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
