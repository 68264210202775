import type { SvgIconProps } from '@mui/material'

import { LogoCortex } from 'components/global/htmlIcons'

import {
  Home,
  LocationCity,
  AlternateEmail,
  EmojiPeople,
  HomeWork,
  Business,
  BusinessCenter,
  Campaign,
  ListAlt,
  ForwardToInbox,
  Wallet,
  ShowChart,
  Code,
  MiscellaneousServices,
  ImageAspectRatio,
  LocalOffer,
  Person,
  Flag,
  Paid,
  Article,
  ManageAccounts,
  Facebook,
  Collections,
  Google,
  Group,
  Phone,
  Contacts,
  DateRange,
  Loyalty,
  Public,
  ShoppingBasket,
  AssignmentInd,
  Train,
  Quiz,
  Security,
  Settings,
  People,
  LibraryBooks,
  Lock,
  Newspaper,
  Image,
  AttachEmail,
  Receipt,
  Mail,
  ConfirmationNumber,
  SettingsSuggest,
} from '@mui/icons-material'

export const ModulesIcon: Record<string, React.ComponentType<SvgIconProps>> = {
  panel: Home,
  realties: LocationCity,
  messages: AlternateEmail,
  clients: EmojiPeople,
  companies: HomeWork,
  brokers: BusinessCenter,
  agencies: Business,
  crms: SettingsSuggest,
  persons: Group,
  campaigns: Campaign,
  leadAdsForms: ListAlt,
  leadsSubmission: ForwardToInbox,
  services: Wallet,
  reports: ShowChart,
  cms: MiscellaneousServices,
  homeApto: ImageAspectRatio,
  attributes: LocalOffer,
  locations: Public,
  partners: ShoppingBasket,
  jobPositions: AssignmentInd,
  railStation: Train,
  frequentlyAskedQuestions: Quiz,
  institutionals: Security,
  feeds: Code,
  configs: Settings,
  users: People,
  logs: LibraryBooks,
  cortex: LogoCortex,
  coupons: ConfirmationNumber,
}

const ModelIcons: Record<string, React.ComponentType<SvgIconProps>> = {
  UserPermission: Lock,
  InternalNews: Newspaper,
  image: Image,
  recipient: AttachEmail,
  AdvertiserTransaction: Receipt,
}

const Icons: Record<string, React.ComponentType<SvgIconProps>> = {
  home: Home,
  mail: Mail,
  localOffer: LocalOffer,
  campaign: Campaign,
  person: Person,
  flag: Flag,
  paid: Paid,
  document: Article,
  crm: ManageAccounts,
  facebook: Facebook,
  gallery: Collections,
  google: Google,
  group: Group,
  phone: Phone,
  contacts: Contacts,
  date: DateRange,
  sale: Loyalty,
}

export const ModelAndModuleIcons: Record<
  string,
  React.ComponentType<SvgIconProps>
> = {
  ...ModulesIcon,
  ...ModelIcons,
  ...Icons,
}
