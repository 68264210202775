import type { LoaderProps } from './types'

import useSidebarStore from 'components/Sidebar/useStore'
import useSubSidebarStore from 'components/SubSideBar/useStore'

import * as S from './styles'

export default function Loader({ suspense }: LoaderProps): React.ReactElement {
  const sidebarOpen = useSidebarStore((state) => state.open)
  const subSidebarDisplay = useSubSidebarStore((state) => state.display)

  return (
    <S.LoaderContainer
      open
      $suspense={suspense ?? false}
      $sidebar={sidebarOpen}
      $subSidebar={subSidebarDisplay}
    >
      <img src="/images/brain-2-versao-3.gif" alt="Brain Loading" />
    </S.LoaderContainer>
  )
}
