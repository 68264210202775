import styled from 'styled-components'

export const CustomToast = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .notify {
    &_wrapper {
      display: flex;
      align-items: center;
    }
    &_text {
      margin-left: 8px;
      margin-right: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`
