import styled, { css } from 'styled-components'

export const BoxAvatar = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.sidebar.background};
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.25);
    position: absolute;
    z-index: 1;

    .profileButton,
    .exitButton {
      color: ${theme.sidebar.text};
    }

    @media ${theme.device.tablet} {
      .MuiAvatar-root {
        width: 40px;
        height: 40px;
      }
    }
  `}
`

export const AvatarMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.sidebar.text};

    .email {
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`

export const MyProfile = styled.span`
  display: inline-block;
  width: 100%;
  padding: 8px;
  border-radius: 5px;

  &:hover {
    background-color: #ffffff40;
  }
`
