import { useState, useEffect, useRef } from 'react'
import { IconButton } from '@mui/material'
import { useApolloClient } from '@apollo/client'
import useStore from '../useStore'
import { SecondaryTypeModal } from 'components/Modals'
import LinkCustom from 'components/LinkCustom'
import Logo from '../Logo'
import UserInfo from '../UserInfo'
import * as S from './styles'
import { Text } from '../Modules/styles'
import {
  ExpandMore,
  ArrowForwardIos,
  Close,
  Menu,
  Group,
  MeetingRoom,
  EmojiEmotions,
} from '@mui/icons-material'
import { useAuth } from 'context/Auth/hook'
import { useNavigate } from 'react-router-dom'

export const ShowUserInfos = () => {
  const { user } = useAuth()
  const nameUser = user.name.split(' ')[0]

  const userInfosMenuRef = useRef(null)

  const [userInfos, setUserInfos] = useState(false)

  useEffect(() => {
    function handleMenuClickOutside(e) {
      if (!userInfosMenuRef.current?.contains(e.target)) {
        setUserInfos(false)
      }
    }

    document.addEventListener('click', handleMenuClickOutside)
    return () => document.removeEventListener('click', handleMenuClickOutside)
  }, [])

  return (
    <div style={{ width: '100%', position: 'relative' }} ref={userInfosMenuRef}>
      <S.BoxLogo onClick={() => setUserInfos(!userInfos)}>
        <Logo />

        <S.TextUser title={nameUser} className="textUser">
          Olá, <span>{nameUser}!</span>
        </S.TextUser>

        <ExpandMore
          className={`expand${userInfos ? ' rotate' : ''}`}
          color="icon"
        />
      </S.BoxLogo>

      <UserInfo show={userInfos} onAction={(value) => setUserInfos(value)} />
    </div>
  )
}

export const CloseDesktop = () => {
  const toggle = useStore((state) => state.toggle)

  return (
    <S.DesktopButton>
      <S.CustomButton
        style={{ padding: '8px 23px' }}
        className="closeAction"
        onClick={toggle}
      >
        <ArrowForwardIos className="closeIcon" color="icon" fontSize="small" />
        <Text className="closeText">Recolher Menu</Text>
      </S.CustomButton>
    </S.DesktopButton>
  )
}

export const CloseMobile = () => {
  const toggle = useStore((state) => state.toggle)

  return (
    <S.MobileButton>
      <IconButton color="standard" onClick={toggle}>
        <Close className="menuClose" />
        <Menu className="menuOpen" />
      </IconButton>
    </S.MobileButton>
  )
}

export const Profile = ({ onAction = () => {} }) => {
  return (
    <S.CustomButton onClick={onAction}>
      <LinkCustom to="/perfil">
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Group color="standard" />
          <Text className="profileButton">Meu Perfil</Text>
        </div>
      </LinkCustom>
    </S.CustomButton>
  )
}

export const Logout = () => {
  const [leaveModal, setLeaveModal] = useState(false)
  const { logout } = useAuth()
  const navigate = useNavigate()

  const client = useApolloClient()

  function handleLogout() {
    client.cache.evict({ fieldName: 'me' })
    client.cache.gc()
    logout()
    navigate('/login')
  }

  return (
    <>
      <S.CustomButton onClick={() => setLeaveModal(true)}>
        <MeetingRoom color="secondary" />
        <Text className="exitButton">Sair do Brain</Text>
      </S.CustomButton>

      {leaveModal && (
        <SecondaryTypeModal
          title="Já vai? #FicaVaiTerBolo"
          closeText="Sem querer"
          actionProps={{ text: 'Tchau!', icon: <EmojiEmotions /> }}
          onClose={() => setLeaveModal(false)}
          onAction={handleLogout}
        />
      )}
    </>
  )
}
