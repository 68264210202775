import { create } from 'zustand'

interface SubSidebarStoreProps {
  display: boolean
  open: boolean
  toggleDisplay: (prop: boolean) => void
  toggle: () => void
}

const useStore = create<SubSidebarStoreProps>((set) => ({
  display: false,
  open: false,
  toggleDisplay: (value): void => set(() => ({ display: value })),
  toggle: (): void => set((state) => ({ open: !state.open })),
}))

export default useStore
