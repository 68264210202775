/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { SvgIconProps } from '@mui/material'

import { SvgIcon } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

export const DragContainer = styled.div`
  width: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  user-select: none;
  .bar {
    border-top: 2px solid ${({ theme }) => theme.colors.textLight};
    width: 100%;
  }
`

const DragIcon = (props: any) => {
  return (
    <DragContainer style={{ cursor: 'pointer' }} {...props}>
      <div className="bar" />
      <div className="bar" />
      <div className="bar" />
    </DragContainer>
  )
}

export default DragIcon

const ProBadge = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0975 11.5715L20.2374 9.44148L20.4974 6.62148C20.5474 6.12148 20.2074 5.66148 19.7274 5.55148L16.9675 4.93148L15.5275 2.49148C15.2675 2.06148 14.7375 1.88148 14.2675 2.08148L11.6775 3.19148L9.08745 2.08148C8.62745 1.88148 8.08745 2.06148 7.83745 2.49148L6.38745 4.92148L3.63745 5.54148C3.14745 5.65148 2.80745 6.10148 2.85745 6.60148L3.11745 9.43148L1.24745 11.5715C0.91745 11.9515 0.91745 12.5115 1.24745 12.8915L3.11745 15.0215L2.85745 17.8515C2.80745 18.3515 3.14745 18.8115 3.62745 18.9215L6.38745 19.5515L7.82745 21.9815C8.08745 22.4115 8.62745 22.5915 9.08745 22.3915L11.6775 21.2615L14.2675 22.3715C14.7275 22.5715 15.2675 22.3915 15.5175 21.9615L16.9575 19.5315L19.7175 18.9015C20.2075 18.7915 20.5374 18.3315 20.4874 17.8315L20.2274 15.0115L22.0875 12.8815C22.2505 12.7028 22.3417 12.4702 22.3435 12.2284C22.3454 11.9866 22.2577 11.7526 22.0975 11.5715Z"
        fill="#0066FF"
      />
      <path
        d="M10.2304 14.83L8.11038 12.71C8.0178 12.6174 7.94436 12.5075 7.89425 12.3865C7.84415 12.2655 7.81836 12.1359 7.81836 12.005C7.81836 11.874 7.84415 11.7444 7.89425 11.6234C7.94436 11.5024 8.0178 11.3925 8.11038 11.3C8.20296 11.2074 8.31287 11.1339 8.43384 11.0838C8.5548 11.0337 8.68445 11.0079 8.81538 11.0079C8.94631 11.0079 9.07596 11.0337 9.19692 11.0838C9.31789 11.1339 9.4278 11.2074 9.52038 11.3L10.9304 12.71L14.4704 9.16995C14.563 9.07737 14.6729 9.00393 14.7938 8.95382C14.9148 8.90372 15.0444 8.87793 15.1754 8.87793C15.3063 8.87793 15.436 8.90372 15.5569 8.95382C15.6779 9.00393 15.7878 9.07737 15.8804 9.16995C15.973 9.26253 16.0464 9.37244 16.0965 9.49341C16.1466 9.61437 16.1724 9.74402 16.1724 9.87495C16.1724 10.0059 16.1466 10.1355 16.0965 10.2565C16.0464 10.3775 15.973 10.4874 15.8804 10.58L11.6404 14.82C11.2604 15.22 10.6204 15.22 10.2304 14.83Z"
        fill="white"
      />
    </svg>
  )
}

const LogoCortex: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 28 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.2585 4.09077L18.6877 0H9.41999L5.74461 4.09077L0.284607 6.57846V20.1723L5.73999 22.6585L9.31076 26.7508H10.5123V29.5H11.4446V26.7508H13.4215V31.9692H14.3538V26.7508H16.4877V29.5H17.42V26.7508H18.5769L22.2523 22.66L27.7123 20.1723V6.57846L22.2569 4.09231L22.2585 4.09077ZM13.8892 11.0446C13.5092 11.0446 13.1985 10.7354 13.1985 10.3538C13.1985 9.97231 13.5077 9.66308 13.8892 9.66308C14.2708 9.66308 14.58 9.97231 14.58 10.3538C14.58 10.7354 14.2708 11.0446 13.8892 11.0446ZM26.8308 11.3077H24.3061V12.24H26.8308V19.6031L21.7215 21.9323L18.1846 25.8677H17.4231V21.6846L20.1415 18.9662C20.3815 19.1062 20.6585 19.1862 20.9554 19.1862C21.8508 19.1862 22.5785 18.4585 22.5785 17.5631C22.5785 16.6677 21.8508 15.94 20.9554 15.94C20.06 15.94 19.3323 16.6677 19.3323 17.5631C19.3323 17.8231 19.3938 18.0677 19.5031 18.2846L16.4908 21.2969V25.8662H14.3569V11.9092C15.0246 11.7077 15.5138 11.0877 15.5138 10.3554C15.5138 9.46 14.7861 8.73231 13.8908 8.73231C12.9954 8.73231 12.2677 9.46 12.2677 10.3554C12.2677 11.0877 12.7554 11.7092 13.4246 11.9092V25.8662H11.4477V21.2985L8.47691 18.3277C8.59845 18.1 8.66922 17.84 8.66922 17.5631C8.66922 16.6677 7.94153 15.94 7.04615 15.94C6.15076 15.94 5.42307 16.6677 5.42307 17.5631C5.42307 18.4585 6.15076 19.1862 7.04615 19.1862C7.32615 19.1862 7.58922 19.1154 7.81999 18.9892L10.5154 21.6846V25.8677H9.71384L6.28307 21.9354L1.17076 19.6046V12.2415H3.89538V11.3092H1.17076V7.14615L6.27999 4.81692L9.81691 0.883077H14.0338H18.2908L21.7215 4.81538L26.8338 7.14615V11.3092L26.8308 11.3077ZM20.2646 17.5615C20.2646 17.1815 20.5738 16.8708 20.9554 16.8708C21.3369 16.8708 21.6461 17.18 21.6461 17.5615C21.6461 17.9431 21.3369 18.2523 20.9554 18.2523C20.5738 18.2523 20.2646 17.9431 20.2646 17.5615ZM7.73384 17.5615C7.73384 17.9415 7.42461 18.2523 7.04307 18.2523C6.66153 18.2523 6.3523 17.9431 6.3523 17.5615C6.3523 17.18 6.66153 16.8708 7.04307 16.8708C7.42461 16.8708 7.73384 17.18 7.73384 17.5615Z"
        fill="#000000"
      />
    </SvgIcon>
  )
}

const LogoApto = () => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 30 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="🖥-🔍-Busca"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Símbolo">
          <polygon
            id="Fill-17"
            fill="#0066FF"
            points="0.000212541046 7.82685185 9.05375264 13.0761574 29.8246806 2.58888889 12.3826177 0.23125"
          ></polygon>
          <polygon
            id="Fill-20"
            fill="#0F0090"
            points="14.1456221 24.948125 7.06304597 8.885625 29.8250112 2.58886574 22.5258794 24.9573843"
          ></polygon>
          <polygon
            id="Fill-22"
            fill="#4295F7"
            points="9.69957042 24.948125 14.204496 24.948125 7.17552742 8.88585648 -9.44626872e-05 7.82659722"
          ></polygon>
          <path
            d="M23.9147643,15.2387731 C23.9147643,15.6325231 23.588868,15.9512731 23.1871654,15.9512731 C22.7852267,15.9512731 22.4595666,15.6325231 22.4595666,15.2387731 C22.4595666,14.8450231 22.7852267,14.5262731 23.1871654,14.5262731 C23.588868,14.5262731 23.9147643,14.8450231 23.9147643,15.2387731"
            id="Fill-25"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const LogoAptoExtended = () => {
  return (
    <svg
      width="95"
      height="32"
      viewBox="0 0 95 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.7269 23.7608C44.4194 23.7608 45.0331 23.7455 45.5681 23.7145C46.103 23.6835 46.5486 23.6267 46.9055 23.5438V18.7402C46.6955 18.6369 46.3548 18.5494 45.8827 18.4767C45.4108 18.4044 44.8389 18.3683 44.1676 18.3683C43.7269 18.3683 43.2599 18.3993 42.7671 18.4614C42.274 18.5235 41.823 18.6527 41.4139 18.8485C41.0047 19.0454 40.6635 19.3135 40.391 19.6546C40.118 19.9955 39.9819 20.4447 39.9819 21.0025C39.9819 22.0359 40.3171 22.7538 40.989 23.1564C41.66 23.5593 42.5728 23.7608 43.7269 23.7608M43.4753 9.3186C44.6501 9.3186 45.6412 9.46866 46.4494 9.76806C47.2566 10.0679 47.9072 10.4915 48.4003 11.0386C48.8932 11.5865 49.2447 12.2371 49.4547 12.9913C49.6642 13.7455 49.7693 14.577 49.7693 15.4859V25.5584C49.5177 25.6001 49.1662 25.6565 48.7152 25.7288C48.2637 25.8015 47.7553 25.8682 47.1889 25.9302C46.6224 25.9923 46.0087 26.0488 45.3477 26.1007C44.6868 26.1521 44.0309 26.1782 43.3808 26.1782C42.4574 26.1782 41.6076 26.0852 40.8316 25.8992C40.0552 25.7133 39.3839 25.419 38.8174 25.0161C38.251 24.6131 37.8103 24.0813 37.4957 23.4199C37.1809 22.7588 37.0236 21.9632 37.0236 21.0335C37.0236 20.1455 37.207 19.3809 37.5743 18.7402C37.9413 18.0999 38.4398 17.5833 39.0693 17.1904C39.6987 16.7984 40.4328 16.5087 41.2723 16.3227C42.1113 16.137 42.9923 16.0439 43.9157 16.0439C44.2094 16.0439 44.5137 16.0592 44.8286 16.0902C45.1432 16.1213 45.442 16.163 45.7254 16.2144C46.0087 16.2662 46.2551 16.3125 46.4649 16.3538C46.6744 16.3955 46.8213 16.4265 46.9055 16.4468V15.641C46.9055 15.1659 46.8529 14.6956 46.7482 14.2308C46.6429 13.7659 46.454 13.3528 46.1818 12.9913C45.9088 12.6299 45.5366 12.3404 45.0646 12.1235C44.5925 11.9065 43.9788 11.7981 43.2235 11.7981C42.2582 11.7981 41.4139 11.8653 40.6901 11.9993C39.9661 12.1341 39.4257 12.2735 39.0693 12.4178L38.7229 10.0316C39.1008 9.86648 39.73 9.706 40.6113 9.55109C41.4924 9.39618 42.4471 9.3186 43.4753 9.3186"
        fill="#2A65F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.1199 17.8104C64.1199 15.9304 63.6478 14.4839 62.7037 13.4715C61.7596 12.4594 60.5006 11.953 58.9271 11.953C58.046 11.953 57.3585 11.9838 56.8659 12.0458C56.3725 12.1079 55.9791 12.1804 55.6854 12.2628V22.4281C56.0422 22.7176 56.5559 22.9966 57.2277 23.265C57.8988 23.5336 58.6329 23.6676 59.4308 23.6676C60.2695 23.6676 60.9886 23.5181 61.5863 23.2184C62.1845 22.919 62.6722 22.5055 63.0498 21.9787C63.4274 21.4519 63.6999 20.832 63.8681 20.119C64.0357 19.4063 64.1199 18.6369 64.1199 17.8104M67.1725 17.8104C67.1725 19.0296 67.0098 20.1556 66.6848 21.1886C66.3594 22.2218 65.8823 23.1098 65.2528 23.8538C64.6233 24.5976 63.8523 25.1763 62.9397 25.5893C62.0272 26.0022 60.9831 26.2092 59.8084 26.2092C58.8641 26.2092 58.0303 26.0851 57.3063 25.8371C56.5825 25.5893 56.0422 25.3518 55.6854 25.1244V31.5707H52.7587V10.2175C53.4512 10.0524 54.3165 9.87152 55.3551 9.67492C56.3937 9.4788 57.5945 9.38062 58.9586 9.38062C60.2173 9.38062 61.3504 9.57722 62.3575 9.96946C63.3646 10.3622 64.2245 10.92 64.9382 11.6429C65.6511 12.3663 66.2018 13.2497 66.5903 14.2929C66.9783 15.3363 67.1725 16.5086 67.1725 17.8104"
        fill="#2A65F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.1763 9.72151H78.3762V12.139H72.1763V19.5768C72.1763 20.3826 72.2391 21.049 72.3651 21.5758C72.4909 22.1026 72.6797 22.5162 72.9316 22.8153C73.1834 23.1154 73.498 23.3268 73.8756 23.4507C74.2535 23.5748 74.6939 23.6367 75.1974 23.6367C76.0787 23.6367 76.7869 23.539 77.3218 23.3424C77.8568 23.146 78.229 23.0066 78.439 22.9239L79.0054 25.3104C78.7115 25.4551 78.1975 25.6357 77.4634 25.8527C76.7288 26.0696 75.8898 26.178 74.9458 26.178C73.8333 26.178 72.9158 26.0386 72.1918 25.7598C71.4683 25.4808 70.8858 25.0624 70.4454 24.5045C70.0048 23.9467 69.6948 23.2599 69.5169 22.4434C69.3384 21.6277 69.2495 20.6822 69.2495 19.6078V5.22769L72.1763 4.73169V9.72151Z"
        fill="#2A65F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.6253 17.7795C91.6253 15.9615 91.2107 14.5205 90.3823 13.4561C89.5532 12.3921 88.4255 11.8599 86.9991 11.8599C85.5721 11.8599 84.4446 12.3921 83.616 13.4561C82.7868 14.5205 82.3728 15.9615 82.3728 17.7795C82.3728 19.5976 82.7868 21.0388 83.616 22.1026C84.4446 23.1671 85.5721 23.6987 86.9991 23.6987C88.4255 23.6987 89.5532 23.1671 90.3823 22.1026C91.2107 21.0388 91.6253 19.5976 91.6253 17.7795M94.6781 17.7795C94.6781 19.0607 94.4893 20.2178 94.1116 21.2502C93.7338 22.2837 93.2037 23.1719 92.5223 23.9157C91.8402 24.6594 91.0273 25.2328 90.0832 25.6357C89.1391 26.0386 88.1109 26.2401 86.9991 26.2401C85.8867 26.2401 84.8591 26.0386 83.9148 25.6357C82.9707 25.2328 82.1574 24.6594 81.4757 23.9157C80.7938 23.1719 80.2643 22.2837 79.8864 21.2502C79.509 20.2178 79.3202 19.0607 79.3202 17.7795C79.3202 16.5193 79.509 15.3673 79.8864 14.3239C80.2643 13.2808 80.7938 12.3867 81.4757 11.643C82.1574 10.8992 82.9707 10.3259 83.9148 9.92297C84.8591 9.52006 85.8867 9.3186 86.9991 9.3186C88.1109 9.3186 89.1391 9.52006 90.0832 9.92297C91.0273 10.3259 91.8402 10.8992 92.5223 11.643C93.2037 12.3867 93.7338 13.2808 94.1116 14.3239C94.4893 15.3673 94.6781 16.5193 94.6781 17.7795"
        fill="#2A65F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000366211 7.95467L9.438 13.4521L31.0901 2.46909L12.9081 0L0.000366211 7.95467Z"
        fill="#0066FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7458 25.8852L7.36279 9.06342L31.0904 2.46899L23.4816 25.8949L14.7458 25.8852Z"
        fill="#001290"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1112 25.8853H14.8072L7.48004 9.06368L0 7.95435L10.1112 25.8853Z"
        fill="#3C98F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9294 15.7169C24.9294 16.1292 24.5897 16.4631 24.1709 16.4631C23.752 16.4631 23.4125 16.1292 23.4125 15.7169C23.4125 15.3045 23.752 14.9707 24.1709 14.9707C24.5897 14.9707 24.9294 15.3045 24.9294 15.7169"
        fill="white"
      />
    </svg>
  )
}

export { ProBadge, LogoCortex, LogoApto, LogoAptoExtended }
