import styled, { css } from 'styled-components'

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1300;
`
export const ModalContent = styled.div`
  ${({ $maxWidth, $sx, theme }) => css`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: calc(100% - 24px);
    max-width: ${$maxWidth ? $maxWidth : 800}px;
    max-height: ${$sx?.maxHeight ? $sx.maxHeight : '90vh'};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: #ffffff;
    border-radius: ${theme.border.radius};
    border-top: 6px solid ${theme.colors.primary};
    z-index: 20;
  `}
`

export const ModalContentImageUpload = styled(ModalContent)`
  background: #f6fcff;
`

export const ModalMask = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
`

export const ModalHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;

    .title {
      font-size: ${theme.font.sizes.xxlarge};
      font-weight: 500;
      color: ${theme.colors.text};
    }

    svg {
      color: ${theme.colors.textLight};
      width: 1.2em;
      height: 1.2em;
      cursor: pointer;
    }
  `}
`

export const ModalFooter = styled.div`
  padding: 16px 24px;
`

export const ButtonsWrapper = styled.div`
  margin: 24px 0 0;
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
`

export const Content = styled.div`
  ${({ theme, $disableOverflow }) => css`
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow-y: ${$disableOverflow ? 'unset' : 'auto'};

    .MuiInputBase-input {
      font-size: ${theme.font.sizes.large};
    }
  `}
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 8px;
  margin-bottom: 10px;
`

export const TitleCategory = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    font-weight: 500;
    margin-top: 10px;

    &.required::after {
      content: ' *';
      color: ${theme.colors.secondary};
      font-weight: 400;
    }
  `}
`

export const IconsWrapper = styled.div`
  svg {
    width: 60px;
    height: 60px;
  }
`

export const Icon = styled.div`
  ${({ theme }) => css`
    background: #f3f3f3;
    padding: 5px;
    border-radius: 5px;
    flex: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    margin-bottom: 7px;
    &::before {
      font-size: 35px;
    }

    &.active {
      background: ${theme.colors.primary};
      color: #fff;
    }

    &:hover {
      background: ${theme.colors.primary};
      color: #fff;
      cursor: pointer;
    }
  `}
`
