import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import App from './App'

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

Sentry.init({
  dsn: 'https://d63cdcfce0784dee8bda0cd54c625c2f@o448379.ingest.sentry.io/6627064',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1,
  environment: import.meta.env.VITE_SENTRY_ENV ?? 'development',
  enabled: import.meta.env.VITE_SENTRY_ENV === 'production',
})
