import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function getFutureTimestamp(minutesAhead = 240) {
  return new Date(Date.now() + minutesAhead * 60000)
}

export function clearToken() {
  cookies.remove('auth_token', { path: '/' })
  cookies.remove('token_lifetime', { path: '/' })
}

export function setToken(token) {
  cookies.set('auth_token', token, { path: '/' })
  cookies.set('token_lifetime', true, {
    path: '/',
    expires: getFutureTimestamp(),
  })
}

export function getTableMainOrder(name) {
  if (!name) {
    return null
  }

  return (JSON.parse(localStorage.getItem('table_order')) ?? {})[name]
}

export function registerTableOrder(name, order) {
  const tablesOrder = JSON.parse(localStorage.getItem('table_order')) ?? {}

  localStorage.setItem(
    'table_order',
    JSON.stringify({ ...tablesOrder, [name]: order }),
  )
}
