import { TextField } from '@mui/material'
import styled, { css } from 'styled-components'

export const Input = styled(TextField)`
  ${({ theme }): ReturnType<typeof css> => css`
    label {
      font-size: ${theme.font.sizes.medium};
      background-color: #fff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      margin: 0 -5px;
      color: #a8aaac;
    }

    .MuiInputBase-input {
      font-size: ${theme.font.sizes.medium};
    }

    .MuiInputLabel-root {
      margin-top: -4px;
    }

    .MuiOutlinedInput-input {
      padding: 12.5px;
    }
    . .MuiFormHelperText-root {
      font-size: ${theme.font.sizes.small};
      margin-left: 0;
    }
  `};
`
