import { Collapse, Avatar } from '@mui/material'
import * as S from './styles'
import { Profile, Logout } from '../Buttons'
import { useAuth } from 'context/Auth/hook'

const UserInfo = ({ show, onAction = () => {} }) => {
  const { user } = useAuth()
  const nameUser = user.name.split(' ')[0]
  const emailUser = user.email
  const imageUser = user.imageDetails?.url ?? ''

  return (
    <S.BoxAvatar>
      <Collapse in={show}>
        <div style={{ padding: '16px' }}>
          <S.AvatarMenu>
            <Avatar src={imageUser} />
            <S.Infos className="infoUser">
              <span className="name">{nameUser}</span>
              <span className="email">{emailUser}</span>
            </S.Infos>
          </S.AvatarMenu>

          <Profile onAction={() => onAction(false)} />
          <Logout />
        </div>
      </Collapse>
    </S.BoxAvatar>
  )
}

export default UserInfo
