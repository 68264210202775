import { useRef } from 'react'
import { Link } from 'react-router-dom'
import useStore from './useStore'
import Modules from './Modules'
import * as Button from './Buttons'
import * as S from './styles'
import { LogoApto, LogoAptoExtended } from 'components/global/htmlIcons'

const Sidebar = ({ width, onResizing = () => {} }) => {
  const sidebarRef = useRef(null)
  const open = useStore((state) => state.open)

  function handleMouseDown() {
    document.body.style.cursor = 'ew-resize'

    let newWidth = localStorage.getItem('@brain:sidebarWidth') ?? 255

    function handleMouseMove(e) {
      const sidebarPosition = sidebarRef.current.getBoundingClientRect()
      newWidth = e.clientX - sidebarPosition.left

      if (newWidth <= 215) return onResizing(215)
      else if (newWidth >= 400) return onResizing(400)
      else return onResizing(newWidth)
    }

    function handleMouseUp() {
      document.body.style.cursor = 'auto'

      if (newWidth <= 215) localStorage.setItem('@brain:sidebarWidth', 215)
      else if (newWidth >= 400) localStorage.setItem('@brain:sidebarWidth', 400)
      else localStorage.setItem('@brain:sidebarWidth', newWidth)

      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <S.SidebarContainer ref={sidebarRef} className="sidebar">
      <S.SibebarContent $width={width} $open={open}>
        <S.InfoWrapper>
          <Button.ShowUserInfos />
          <Button.CloseMobile />
        </S.InfoWrapper>

        <Modules />

        <S.ExitSection>
          <Button.CloseDesktop />
        </S.ExitSection>

        <S.ByApto $sidebarOpen={open}>
          {open ? (
            <>
              <span>Criado pelo Apto</span>

              <Link to="https://apto.vc/" target="_blank">
                <LogoAptoExtended aria-label="Ilustração do logotipo do Apto" />
              </Link>
            </>
          ) : (
            <Link to="https://apto.vc/" target="_blank">
              <LogoApto />
            </Link>
          )}
        </S.ByApto>
      </S.SibebarContent>

      <S.ResizeBar $open={open} onMouseDown={handleMouseDown} />
    </S.SidebarContainer>
  )
}

export default Sidebar
