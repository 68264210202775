import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useStore from '../useStore'
import { pages } from 'components/global/Modules/pages'
import { ModulesIcon } from 'components/global/Modules'
import BrainTooltip from 'components/global/BrainTooltip'
import LinkCustom from 'components/LinkCustom'
import translateModule from 'utils/functions/translateModuleName'
import * as S from './styles'
import { VisibilityOff } from '@mui/icons-material'
import { useAuth } from 'context/Auth/hook'

const Modules = () => {
  const open = useStore((state) => state.open)
  const { user } = useAuth()
  const { pathname } = useLocation()
  const [sideItems, setSideItems] = useState([])
  const baseUrl = pathname.split('/')[1]

  function getModuleProps(mod, link) {
    const Icon = ModulesIcon[mod] ?? VisibilityOff

    return {
      name: translateModule(mod),
      to: link,
      Icon: <Icon />,
    }
  }

  useEffect(() => {
    const rawItems = []
    pages.forEach((pageGroup, index) => {
      if (!rawItems[index]) rawItems.push([])

      pageGroup.forEach((page) => {
        const internals = page.internals?.filter(
          (item) => user.permissions[item.module] > 0,
        )

        if (
          internals?.length === 0 ||
          (!['panel', 'cms', 'config'].includes(page.module) &&
            user.permissions[page.module] === 0)
        ) {
          return
        }

        rawItems[index].push({
          ...getModuleProps(page.module, page.link ?? internals?.[0].link),
          internals: internals?.map((item) => {
            return getModuleProps(item.module, item.link)
          }),
        })
      })
    })

    setSideItems(rawItems.filter((item) => item.length))
  }, [])

  return (
    <S.ModulesSection>
      {sideItems.map((pageGroup, index) => (
        <div key={index}>
          {pageGroup.map((page, index) => (
            <S.SidebarItem
              key={index}
              selected={
                baseUrl === page.to ||
                page.internals?.map(({ to }) => to).includes(baseUrl)
              }
            >
              <BrainTooltip title={open ? '' : page.name} placement="right">
                <div style={{ width: '100%' }}>
                  <LinkCustom to={`/${page.to}`}>
                    <S.Wrapper>
                      {page.Icon}
                      {open && <S.Text>{page.name}</S.Text>}
                    </S.Wrapper>
                  </LinkCustom>
                </div>
              </BrainTooltip>
            </S.SidebarItem>
          ))}
        </div>
      ))}
    </S.ModulesSection>
  )
}

export default Modules
