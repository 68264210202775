import type { LoaderContainerStyle } from './types'

import Backdrop from '@mui/material/Backdrop'
import styled, { css } from 'styled-components'

export const LoaderContainer = styled(Backdrop)<LoaderContainerStyle>`
  ${({ theme, $suspense, $sidebar, $subSidebar }) => css`
    &.MuiBackdrop-root {
      background-color: #ffffff;
      position: absolute;
      z-index: 302;
    }

    img {
      max-width: 100%;
    }

    ${$suspense &&
    css`
      @media ${theme.device.laptopS} {
        left: calc(${$sidebar ? '225px' : '82px'}) !important;
      }

      @media ${theme.device.desktopS} {
        left: calc(
          ${$sidebar ? '225px' : '82px'} + ${$subSidebar ? '225px' : '0px'}
        ) !important;
      }
    `}
  `}
`
