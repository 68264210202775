import { ListItemButton } from '@mui/material'
import styled, { css } from 'styled-components'

export const BoxLogo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 100%;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.15s;
    cursor: pointer;

    &:hover {
      background-color: #0000000a;
    }

    .expand {
      transition: transform 0.4s ease-in-out;
    }

    .rotate {
      transform: rotate(180deg);
    }

    @media ${theme.device.tablet} {
      .expand {
        display: initial !important;
      }
    }

    @media ${theme.device.laptopS} {
      width: 100%;
      padding: 8px;
    }
  `}
`

export const TextUser = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.sidebar.text};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-weight: 700;
      text-transform: capitalize;
    }
  `};
`

export const CustomButton = styled(ListItemButton)`
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    overflow: hidden;
  }
`

export const MobileButton = styled.div`
  ${({ theme }) => css`
    display: none;

    @media ${theme.device.tablet} {
      display: initial;
    }
  `}
`

export const DesktopButton = styled.div`
  ${({ theme }) => css`
    display: none;

    @media ${theme.device.laptopS} {
      width: 100%;
      display: initial;
    }
  `}
`
