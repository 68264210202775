import type { FCDefaultProps, SidebarThemeLabel } from 'types'

import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ptBtLocale from 'date-fns/locale/pt-BR'
import { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import 'styles/fonts.css'
import { MuiCustomTheme, sidebarTheme, theme } from 'styles/theme'

import { AuthProvider } from 'context/Auth'

import { MyToast } from 'components/Toast'

import Router from './router'
import { GlobalStyles } from './styles/globalStyles'

const ThemeContainer: React.FC<FCDefaultProps> = ({ children }) => {
  const [sidebarThemeLabel, setSidebarThemeLabel] = useState(
    (localStorage.getItem('sidebar_theme') as SidebarThemeLabel) ?? 'white',
  )

  useEffect(() => {
    function changeTheme(): void {
      setSidebarThemeLabel(
        (localStorage.getItem('sidebar_theme') as SidebarThemeLabel) ?? 'white',
      )
    }

    document.addEventListener('sidebar:theme', changeTheme)
    return () => document.removeEventListener('sidebar:theme', changeTheme)
  }, [])

  return (
    <StyledThemeProvider
      theme={{
        ...theme,
        ...sidebarTheme[sidebarThemeLabel],
      }}
    >
      {children}
    </StyledThemeProvider>
  )
}

const App: React.FC = () => (
  <ThemeContainer>
    <ThemeProvider theme={MuiCustomTheme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ptBtLocale}
      >
        <MyToast />

        <AuthProvider>
          <Router />
        </AuthProvider>

        <GlobalStyles />
      </LocalizationProvider>
    </ThemeProvider>
  </ThemeContainer>
)

export default App
