import { create } from 'zustand'

interface SidebarStoreProps {
  open: boolean
  setModalState: (prop: boolean) => void
  toggle: () => void
}

const useStore = create<SidebarStoreProps>((set) => ({
  open: localStorage.getItem('@brain:sidebarOpen') === 'true',
  setModalState: (value): void => {
    set(() => {
      localStorage.setItem('@brain:sidebarOpen', JSON.stringify(value))
      return { open: value }
    })
  },
  toggle: (): void =>
    set((state) => {
      localStorage.setItem('@brain:sidebarOpen', JSON.stringify(!state.open))
      return { open: !state.open }
    }),
}))

export default useStore
