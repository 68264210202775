import type { TooltipProps } from '@mui/material'

import { Tooltip, tooltipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const BrainTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow={props.arrow ?? true}
    placement={props.placement ?? 'top'}
    disableInteractive={props.disableInteractive ?? true}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '1px 1px 6px 1px rgb(0 0 0 / 20%)',
    backgroundColor: theme.palette.text.primary + 'fa',
    color: '#fff',
    fontSize: 14,
    fontWeight: 400,
    padding: '4px 12px',
    lineHeight: '17px',
  },
  ['& p']: {
    color: '#fff',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.primary + 'fa',
  },
}))

export default BrainTooltip
