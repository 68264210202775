import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LeaveModal } from 'components/Modals'

const LinkWrapper = styled(Link)`
  width: 100%;
  text-decoration: none;
`

const LinkCustom = ({ children, to = '#' }) => {
  const [modalOpened, setModalOpened] = useState(false)
  const [hasChange, setHasChange] = useState(false)
  const { pathname } = useLocation()

  function handleConfirm() {
    if (hasChange) {
      setModalOpened(true)
    }
  }

  useEffect(() => {
    const editPath =
      pathname.includes('/edit') || pathname.includes('/register')

    if (!hasChange && editPath) {
      setHasChange(true)
    } else if (hasChange && !editPath) {
      setHasChange(false)
    }
  }, [pathname])

  return (
    <>
      {hasChange ? (
        <div style={{ width: '100%' }} onClick={handleConfirm}>
          {children}
        </div>
      ) : (
        <LinkWrapper to={to}>{children}</LinkWrapper>
      )}
      {modalOpened && (
        <LeaveModal onClose={() => setModalOpened(false)} to={to} />
      )}
    </>
  )
}

export default LinkCustom
