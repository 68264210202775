import * as S from './style'
import { Cancel, Close, Delete, Done, Save } from '@mui/icons-material'
import { Button, CircularProgress, Typography } from '@mui/material'

import { useEffect, useState } from 'react'
import InputText from 'components/InputText'
import { Link } from 'react-router-dom'

function Modal({
  children,
  title = 'Insira um titulo',
  onClose = () => {},
  maxWidth,
  description,
  disableOverflow,
  footer,
  sx = {},
}) {
  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', close)
    return () => document.removeEventListener('keydown', close)
  }, [])

  return (
    <S.ModalContainer>
      <S.ModalContent $maxWidth={maxWidth} $sx={sx}>
        <S.ModalHeader>
          <div>
            <p className="title">{title}</p>
            {description && <Typography>{description}</Typography>}
          </div>
          <Cancel onClick={onClose} />
        </S.ModalHeader>
        <S.Content $disableOverflow={disableOverflow}>{children}</S.Content>
        {footer && <S.ModalFooter>{footer}</S.ModalFooter>}
      </S.ModalContent>
      <S.ModalMask />
    </S.ModalContainer>
  )
}

export default Modal

export const AddAndEditModal = ({
  onClose = () => {},
  onAction = () => {},
  regex,
  fieldValue = '',
  title = 'Adicionar',
  placeholder = 'Nome',
}) => {
  const [value, setValue] = useState(fieldValue)

  const SaveData = (e) => {
    if (e.key === 'Enter') {
      if (regex) {
        if (regex.test(value)) {
          onAction(value)
          onClose()
        }
      } else {
        onAction(value)
        onClose()
      }
    }
  }

  return (
    <Modal title={title} maxWidth={650} onClose={onClose}>
      <InputText
        placeholder={placeholder}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        onKeyDown={SaveData}
        autoFocus
      />
      <S.ButtonsWrapper>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<Close />}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          disabled={regex ? !regex.test(value) : false}
          onClick={() => {
            onAction(value)
            onClose()
          }}
        >
          Salvar
        </Button>
      </S.ButtonsWrapper>
    </Modal>
  )
}

export const ConfirmationModal = ({
  title = 'Tem certeza que deseja confirmar essa ação?',
  loading = false,
  onClose = () => {},
  onAction = () => {},
}) => {
  if (loading) {
    return <LoadingModal />
  }

  return (
    <Modal title={title} maxWidth={650} onClose={onClose}>
      <S.ButtonsWrapper>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<Close />}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button variant="contained" startIcon={<Done />} onClick={onAction}>
          Confirmar
        </Button>
      </S.ButtonsWrapper>

      {loading && <LoadingModal />}
    </Modal>
  )
}

export const RemoveModal = ({
  onClose = () => {},
  onAction = () => {},
  title = 'Tem certeza que deseja apagar esse item?',
}) => {
  return (
    <Modal title={title} maxWidth={650} onClose={onClose}>
      <S.ButtonsWrapper>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<Close />}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Delete />}
          onClick={() => {
            onAction()
            onClose()
          }}
        >
          Apagar
        </Button>
      </S.ButtonsWrapper>
    </Modal>
  )
}

export const LeaveModal = ({ onClose = () => {}, to }) => {
  return (
    <Modal
      title="Tem certeza que deseja sair?"
      description="Dados alterados, não serão salvos!"
      maxWidth={600}
      onClose={onClose}
    >
      <S.ButtonsWrapper>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<Close />}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          LinkComponent={Link}
          to={to}
          color="secondary"
          variant="contained"
          startIcon={<Done />}
          onClick={onClose}
        >
          Sair
        </Button>
      </S.ButtonsWrapper>
    </Modal>
  )
}

export const SecondaryTypeModal = ({
  onClose = () => {},
  onAction = () => {},
  title = '',
  closeText = '',
  actionProps = {
    text: '',
    icon: '',
  },
}) => {
  return (
    <Modal title={title} maxWidth={650} onClose={onClose}>
      <S.ButtonsWrapper>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<Close />}
          onClick={onClose}
        >
          {closeText}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          startIcon={actionProps.icon}
          onClick={() => {
            onAction()
            onClose()
          }}
        >
          {actionProps.text}
        </Button>
      </S.ButtonsWrapper>
    </Modal>
  )
}

export const GlobalTypeModal = ({
  onClose = () => {},
  onAction = () => {},
  title = '',
  closeText = 'Cancelar',
  actionProps = {
    text: '',
    icon: '',
  },
  buttonTypes = {
    close: 'secondary',
    action: 'primary',
  },
}) => {
  return (
    <Modal title={title} maxWidth={650} onClose={onClose}>
      <S.ButtonsWrapper>
        <Button
          color={buttonTypes.close || 'secondary'}
          variant="outlined"
          startIcon={<Close />}
          onClick={onClose}
        >
          {closeText || 'Cancelar'}
        </Button>
        <Button
          color={buttonTypes.action || 'primary'}
          variant="contained"
          startIcon={actionProps.icon || <Save />}
          onClick={() => {
            onAction()
            onClose()
          }}
        >
          {actionProps.text || 'Salvar'}
        </Button>
      </S.ButtonsWrapper>
    </Modal>
  )
}

export const CustomButtonsContainer = ({
  onClose,
  onAction,
  secondaryButton = { type: '', icon: '', label: '' },
  mainButton = { type: '', icon: '', label: '' },
}) => {
  return (
    <S.ButtonsWrapper>
      <Button
        color={secondaryButton?.type || 'secondary'}
        variant="outlined"
        startIcon={secondaryButton?.icon || <Close />}
        onClick={onClose}
      >
        {secondaryButton.label || 'Cancelar'}
      </Button>
      <Button
        color={mainButton?.type || 'primary'}
        variant="contained"
        startIcon={mainButton?.icon || <Done />}
        onClick={onAction}
      >
        {mainButton?.label || 'Salvar'}
      </Button>
    </S.ButtonsWrapper>
  )
}

export const LoadingModal = () => {
  return (
    <S.ModalContainer>
      <S.ModalContent $maxWidth="629">
        <div
          style={{ display: 'flex', justifyContent: 'center', padding: '24px' }}
        >
          <CircularProgress />
        </div>
      </S.ModalContent>
      <S.ModalMask />
    </S.ModalContainer>
  )
}
