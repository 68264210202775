import React from 'react'
import { ToastContainer } from 'react-toastify'

import * as S from './styles'

export const MyToast = ({ duration = 3000 }) => {
  return (
    <S.Wrapper>
      <ToastContainer
        theme="dark"
        position="top-right"
        autoClose={duration}
        pauseOnFocusLoss={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnHover={true}
      ></ToastContainer>
    </S.Wrapper>
  )
}

export default MyToast
