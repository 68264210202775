import { ListItemButton } from '@mui/material'
import styled, { css } from 'styled-components'

export const SubSidebarContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: calc(100vh - 66px - 66px);
    padding: 0 16px 16px;
    background-color: ${theme.colors['bgLight-02']};
    left: 0;
    top: 100%;

    @media ${theme.device.laptopS} {
      height: calc(100vh - 66px);
    }

    @media ${theme.device.desktopS} {
      height: auto;
      padding: 0;
    }
  `}
`

export const SubSidebarContainer = styled.nav`
  ${({ theme, $open }) => css`
    display: flex;
    flex-direction: column;
    width: 225px;
    height: 100vh;
    padding: 16px;
    background-color: ${theme.colors['bgLight-02']};
    box-shadow:
      0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%),
      0px 1px 8px 0px rgb(0 0 0 / 12%);
    color: ${theme.sidebar.text};
    position: relative;
    z-index: 1009;

    @media ${theme.device.desktopS} {
      padding: 32px 16px;
    }

    @media ${theme.device.laptop} {
      width: 100%;
      height: 66px;

      .open {
        display: ${$open ? 'none' : 'initial'};
      }

      .close {
        display: ${$open ? 'initial' : 'none'};
      }

      ${SubSidebarContent} {
        display: ${$open ? 'initial' : 'none'};
        position: absolute;
      }
    }
  `}
`

export const MobileToggle = styled.div`
  ${({ theme }) => css`
    @media ${theme.device.desktopS} {
      display: none;
    }
  `}
`

export const SidebarItem = styled(ListItemButton)`
  ${({ theme, selected }) => css`
    &.MuiListItemButton-root {
      flex-grow: 0;
      padding: 0;
      border-radius: 5px;
      overflow: hidden;
    }

    ${selected &&
    css`
      ${Text} {
        color: ${theme.colors.primary};
      }
    `}
  `}
`

export const Text = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    padding: 8px 16px;
    color: ${theme.colors.text};
    letter-spacing: 0.4px;
  `}
`
