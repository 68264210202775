import BrainLayout from 'components/Layout'
import { useAuth } from 'context/Auth/hook'
import { Navigate, Outlet } from 'react-router-dom'
import { translateModuleLink } from 'utils/functions/translateModuleName'
import { useModulePermission } from 'utils/hooks/useModulePermission'

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth()

  if (!user) return <Navigate to="/login" />
  return children
}

export const RouteLayout = () => {
  return (
    <ProtectedRoute>
      <BrainLayout>
        <Outlet />
      </BrainLayout>
    </ProtectedRoute>
  )
}

export const ProtectedLoggedRoute = ({ children, path = '' }) => {
  const perm = useModulePermission(translateModuleLink(path.split('/')[0]))

  if (perm >= 1) return children
  return <Navigate to="/" />
}
