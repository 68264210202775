import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;

  input::-webkit-calendar-picker-indicator {
    display: none !important;
  }

  .Mui-disabled {
    .MuiInputBase-input {
      background-color: ${({ theme }) => theme.colors['bgLight-02']} !important;
    }

    &.MuiInputLabel-root {
      background-color: #ffffff00 !important;
    }
  }
`
