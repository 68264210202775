import { toast } from 'react-toastify'
import { CustomComponent } from './components'

function notify(type = '', label, option) {
  return (toast?.[type] ?? toast)(
    ({ toastProps }) => (
      <CustomComponent type={type} label={label} toastId={toastProps.toastId} />
    ),
    option,
  )
}

export default notify
