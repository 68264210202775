import { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --ck-z-default: 301 !important;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    background: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${({ theme }) => theme.font.family} !important;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ${({ theme }) => css`
    html {
      font-size: ${theme.font.sizes.medium};
    }

    body {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      background-color: ${theme.colors.BGLight};
    }

    b {
      font-weight: 500;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${theme.colors.text};
    }

    .required-asterisk {
      color: ${theme.colors.secondary};
    }

    .default {
      &_color {
        color: ${theme.colors.text};
      }

      &_weight {
        color: ${theme.colors.text};
        font-weight: 500;
      }
    }

    .apto {
      &_divider {
        border-bottom: 1px solid ${theme.colors.border};
      }

      &_text {
        &--small {
          color: ${theme.colors.text};
          font-size: ${theme.font.sizes.small};
        }
      }

      &_title {
        &--normal {
          color: ${theme.colors.text};
          font-size: ${theme.font.sizes.large};
        }
      }
    }

    .brain_color {
      &--secondary {
        color: ${theme.colors.secondary};
      }
    }
  `}
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white  inset !important;
  }
  
  * {
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #c4c4c4;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #a4a4a4;
    }
  }
`

export { GlobalStyles }
