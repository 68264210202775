import { gql } from '@apollo/client'

export const USER_PROFILE = gql`
  query UserProfile {
    profile {
      id
      name
      email
      imageDetails {
        id
        url
      }
      active
      historyLimit
      permissions {
        module
        model
        value
        actions
      }
      options {
        id
        name
        value
      }
    }
  }
`

export const USER_PERMISSIONS = gql`
  query UserProfile {
    query: profile {
      permissions {
        module
        model
        value
        actions
      }
    }
  }
`
